/* ======================================================================== */
/* 13. PJAX Update Nodes */
/* ======================================================================== */
function PJAXUpdateNodes(data) {

	return new Promise(function (resolve, reject) {

		var
			$nextContainer = $($.parseHTML(data.next.html)),
			nodesToUpdate = [
				'.header',
				'.header__wrapper-overlay-menu',
				'.transition-curtain'
			]; // selectors of elements that needed to update

		$.each(nodesToUpdate, function () {

			var
				$item = $(this),
				$nextItem = $nextContainer.find(this);

			// sync attributes if element exist in the new container
			if ($nextItem.length) {
				syncAttributes($nextItem, $item);
			}

		});

		resolve(true);
		f
	});

}
